/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { findInspectionReportUrl } from '../../../graphql/queries';
import { AWSAuthMode, CustomHeaders } from '../../../Shared/Constants/App';
import { Loader } from '../../Jobs/Components/JobView/JobsView.styles';
import PageTitle from '../../../Shared/Components/Common/PageTitle/PageTitle';
import { ReportsContainer } from './Reports.styles';

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

const Reports: React.FC = () => {
  const { t } = useTranslation(['dashboard', 'reports']);

  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch the inspection report URL
  const fetchInspectionReportUrl = async () => {
    try {
      const associateProfileDetails: IGraphQLResponse = await API.graphql(
        {
          query: findInspectionReportUrl,
          variables: {},
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      const reportUrl = associateProfileDetails?.data?.findInspectionReportUrl ?? '';
      setIframeUrl(reportUrl);
    } catch (e: any) {
      console.error('Error fetching the report URL:', e.errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInspectionReportUrl();
  }, []);

  if (loading) {
    return (
      <Loader>
        <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
      </Loader>
    );
  }

  if (!iframeUrl) {
    return <div>No report available</div>;
  }

  return (
    <>
      <PageTitle title={t('dashboard:Reports')} />
      <ReportsContainer>
        <iframe
          id="report-iframe"
          src={iframeUrl}
          frameBorder="0"
          width="100%"
          height="100%"
          title="Metabase Dashboard"
          allowTransparency
        />
      </ReportsContainer>
    </>
  );
};

export default Reports;
