/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allBidAttachments = /* GraphQL */ `
  query AllBidAttachments($allBidAttachmentsInput: AllBidAttachmentsInput) {
    allBidAttachments(allBidAttachmentsInput: $allBidAttachmentsInput) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      tags
    }
  }
`;
export const allBidAttachmentsWithLatestVersion = /* GraphQL */ `
  query AllBidAttachmentsWithLatestVersion(
    $allBidAttachmentsInput: AllBidAttachmentsInput
  ) {
    allBidAttachmentsWithLatestVersion(
      allBidAttachmentsInput: $allBidAttachmentsInput
    ) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      tags
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($keyId: String!) {
    getComments(keyId: $keyId) {
      commentId
      keyId
      keyType
      comment
      commenterType
      dataStatus
    }
  }
`;
export const conversations = /* GraphQL */ `
  query Conversations(
    $customerId: String
    $vendorId: String
    $jobId: String
    $bidId: String
    $bidVersion: Int
    $conversationId: String
    $limit: Int
    $pageNumber: Int
  ) {
    conversations(
      customerId: $customerId
      vendorId: $vendorId
      jobId: $jobId
      bidId: $bidId
      bidVersion: $bidVersion
      conversationId: $conversationId
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        conversationId
        customerId
        vendorId
        bidId
        bidVersion
        jobId
        jobName
        customerName
        vendorName
        countOfMessages
        messages {
          messageId
          message
          messageType
          isRead
          organizationId
          userName
          userRoleId
          userRoleName
          conversationContext
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const allJobAttachments = /* GraphQL */ `
  query AllJobAttachments($allJobAttachmentInput: AllJobAttachmentInput) {
    allJobAttachments(allJobAttachmentInput: $allJobAttachmentInput) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
    }
  }
`;
export const allJobAttachmentsForBidSummary = /* GraphQL */ `
  query AllJobAttachmentsForBidSummary(
    $allJobAttachmentInput: JobAttachmentsInput
  ) {
    allJobAttachmentsForBidSummary(
      allJobAttachmentInput: $allJobAttachmentInput
    ) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
    }
  }
`;
export const allBidSubmissionsForJobId = /* GraphQL */ `
  query AllBidSubmissionsForJobId($jobId: String!) {
    allBidSubmissionsForJobId(jobId: $jobId) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const bidProposalSummary = /* GraphQL */ `
  query BidProposalSummary($bidId: String!) {
    bidProposalSummary(bidId: $bidId) {
      vendorId
      vendorName
      vendorEmail
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      comments
      isFlatMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      draftStatus
      dataStatus
      bidTemplate
      bidCreatedFromSample
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      jobCustodianAddress
      jobCustodianAddressName
      customerAddress
      customerAddressName
      jobs {
        intReferenceId
        customerEmail
        customerName
        customerId
        createdBy
        startDate
        stopDate
        jobDueDates {
          mdDueType
          dueDate
        }
        jobFrequencies {
          fromTime
          toTime
        }
        jobServices {
          mdServiceId
        }
        jobFacilities {
          facilityAddress
          customerName
        }
      }
      bidSimpleEstimation {
        jobId
        estimationId
        bidId
        mdCategoryId
        mdTaskId
        mdMeasureType
        rate
        quantity
        totalCost
        isVendorAdded
        isBillable
        numberOfOccurrences
        mdUnits
      }
      bidAdditionalExpenses {
        bidId
        bidAdditionalExpenseId
        name
        description
        bidSimpleEstimationEstimationId
        rate
        quantity
        totalCost
        mdUnits
        mdServiceCategoryId
        mdAdditionalExpenseCategory
        draftStatus
      }
    }
  }
`;
export const jobBid = /* GraphQL */ `
  query JobBid($jobId: String!, $vendorId: String!) {
    jobBid(jobId: $jobId, vendorId: $vendorId) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const jobBids = /* GraphQL */ `
  query JobBids($jobId: String!, $includeRejectedBids: BooleanType) {
    jobBids(jobId: $jobId, includeRejectedBids: $includeRejectedBids) {
      jobBidId
      jobId
      vendorId
      vendorName
      bidName
      vendorEmail
      totalCost
      mdFrequencyType
      comments
      submittedBy
      bidSubmissionDate
      vendorBidId
      vendorBidIdRecSeq
      jobEstimations {
        jobEstimationId
        vendorEstimationId
        jobId
        jobBidId
        mdCategoryId
        quantity
        rate
        quoteAmount
        dataStatus
        bidVersion
      }
      dataStatus
      bidVersion
      revisedJobBids {
        jobBidId
        jobId
        vendorId
        vendorName
        bidName
        vendorEmail
        totalCost
        mdFrequencyType
        comments
        submittedBy
        bidSubmissionDate
        vendorBidId
        vendorBidIdRecSeq
        jobEstimations {
          jobEstimationId
          vendorEstimationId
          jobId
          jobBidId
          mdCategoryId
          quantity
          rate
          quoteAmount
          dataStatus
          bidVersion
        }
        dataStatus
        bidVersion
      }
      bidComments {
        commentId
        comment
        commentBy
        commentByName
        commenterRoleId
        commenterRoleName
        entityType
        organizationId
        commentDate
        vendorBidId
        jobId
        bidVersion
        actionType
      }
    }
  }
`;
export const jobBidSummary = /* GraphQL */ `
  query JobBidSummary($bidId: String!) {
    jobBidSummary(bidId: $bidId) {
      vendorId
      vendorName
      vendorEmail
      bidId
      jobId
      bidName
      totalCost
      comments
      profitMargin
      isFlatMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      draftStatus
      dataStatus
      bidTemplate
      bidCreatedFromSample
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      bidSimpleEstimation {
        jobId
        estimationId
        bidId
        mdCategoryId
        mdTaskId
        mdMeasureType
        rate
        quantity
        isOverTime
        totalCost
        isVendorAdded
        isBillable
        numberOfOccurrences
        mdUnits
        tasks {
          taskId
          taskName
        }
      }
      bidAdditionalExpenses {
        bidId
        bidAdditionalExpenseId
        name
        description
        bidSimpleEstimationEstimationId
        rate
        quantity
        totalCost
        mdUnits
        mdServiceCategoryId
        mdAdditionalExpenseCategory
        draftStatus
      }
      mdFrequencyType
      customerId
      customerName
      isCustomerApprovalAvailable
      transactionStatus
      bidComments {
        commentId
        comment
        commentBy
        commentByName
        commenterRoleId
        commenterRoleName
        entityType
        organizationId
        commentDate
        vendorBidId
        jobId
        bidVersion
        actionType
      }
    }
  }
`;
export const allJobBidAdditionalExpenses = /* GraphQL */ `
  query AllJobBidAdditionalExpenses($jobId: String!, $bidId: String!) {
    allJobBidAdditionalExpenses(jobId: $jobId, bidId: $bidId) {
      bidId
      bidAdditionalExpenseId
      name
      description
      bidSimpleEstimationEstimationId
      rate
      quantity
      totalCost
      mdUnits
      mdServiceCategoryId
      mdAdditionalExpenseCategory
      draftStatus
    }
  }
`;
export const jobBidAdditionalExpenses = /* GraphQL */ `
  query JobBidAdditionalExpenses(
    $bidId: String!
    $additionalCategoryId: String!
  ) {
    jobBidAdditionalExpenses(
      bidId: $bidId
      additionalCategoryId: $additionalCategoryId
    ) {
      bidId
      bidAdditionalExpenseId
      name
      description
      bidSimpleEstimationEstimationId
      rate
      quantity
      totalCost
      mdUnits
      mdServiceCategoryId
      mdAdditionalExpenseCategory
      draftStatus
    }
  }
`;
export const allJobBidEstimation = /* GraphQL */ `
  query AllJobBidEstimation($jobId: String!, $bidId: String!) {
    allJobBidEstimation(jobId: $jobId, bidId: $bidId) {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
      }
    }
  }
`;
export const jobBidEstimation = /* GraphQL */ `
  query JobBidEstimation($bidId: String!, $serviceCategoryId: String!) {
    jobBidEstimation(bidId: $bidId, serviceCategoryId: $serviceCategoryId) {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
      }
    }
  }
`;
export const jobFacility = /* GraphQL */ `
  query JobFacility($customerId: String!, $jobId: String!) {
    jobFacility(customerId: $customerId, jobId: $jobId) {
      jobId
      facilityId
      mdFacilityType
      facilityAddress
      remarks
      status
      jobStartDate
      jobEndData
    }
  }
`;
export const jobInterests = /* GraphQL */ `
  query JobInterests($customerId: String!, $vendorId: String!) {
    jobInterests(customerId: $customerId, vendorId: $vendorId) {
      vendorId
      jobId
      actionType
      actionTime
      actionUser
    }
  }
`;
export const jobInterestVendors = /* GraphQL */ `
  query JobInterestVendors($jobId: String!, $isInterested: Boolean!) {
    jobInterestVendors(jobId: $jobId, isInterested: $isInterested) {
      vendorId
      jobId
      actionType
      actionTime
      actionUser
    }
  }
`;
export const getJobsForPrimeVendor = /* GraphQL */ `
  query GetJobsForPrimeVendor(
    $organizationId: String
    $jobForDmInput: JobsForPrimeVendorInput
  ) {
    getJobsForPrimeVendor(
      organizationId: $organizationId
      jobForDmInput: $jobForDmInput
    ) {
      data {
        jobId
        jobName
        startDate
        stopDate
        dataStatus
        jobType
        specialInstructions
        draftStep
        draftStatus
        createdOn
        createdBy
        modifiedOn
        modifiedBy
        bidCount
        customerId
        customerName
        customerEmail
        parentJobId
        jobCustodian
        intId
        jobFacilities {
          facilityId
          facilityAddress
        }
        jobDueDates {
          jobId
          mdDueType
          dueDescription
          dueDate
        }
        jobBids {
          bidId
          vendorId
          vendorName
          vendorEmail
          mdFrequencyType
          bidName
          totalCost
          profitMargin
          isFlatMargin
          finalQuote
          comments
          bidEstimationType
          bidCreationDate
          draftStep
          draftStatus
          dataStatus
          bidSubmissionDate
          bidVersionCreatedDate
          bidVersion
        }
        jobVendors {
          vendorId
          mdVendorType
          hasSignedUp
        }
        jobAttachments {
          attachmentId
          mdAttachmentType
          fileName
          mdFileContext
          fileExtension
          url
          dataStatus
          comments
          jobId
          facilityId
          tags
        }
        jobServices {
          mdServiceId
          mdServiceUnits
          serviceMeasure
          actualMdServiceUnits
          actualServiceMeasure
        }
        jobFrequencies {
          mdShiftType
          mdScheduleType
          mdServiceId
          scheduleDays
          fromTime
          toTime
          cronExpression
          repeatitions
          repetition
        }
        subJobs {
          jobId
          jobName
          startDate
          stopDate
          dataStatus
          jobType
          specialInstructions
          draftStep
          draftStatus
          createdOn
          createdBy
          modifiedOn
          modifiedBy
          bidCount
          customerId
          customerName
          customerEmail
          parentJobId
          jobFacilities {
            facilityId
            facilityAddress
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          jobBids {
            bidId
            vendorId
            vendorName
            vendorEmail
            mdFrequencyType
            bidName
            totalCost
            profitMargin
            isFlatMargin
            finalQuote
            comments
            bidEstimationType
            bidCreationDate
            draftStep
            draftStatus
            dataStatus
            bidSubmissionDate
            bidVersionCreatedDate
            bidVersion
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            vendorId
            mdVendorType
            hasSignedUp
          }
          jobAttachments {
            attachmentId
            mdAttachmentType
            fileName
            mdFileContext
            fileExtension
            url
            dataStatus
            comments
            jobId
            facilityId
            tags
          }
          jobServices {
            mdServiceId
            mdServiceUnits
            serviceMeasure
            actualMdServiceUnits
            actualServiceMeasure
          }
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getReferenceJobMasters = /* GraphQL */ `
  query GetReferenceJobMasters(
    $referenceJobMasterInput: ReferenceJobMasterInput
  ) {
    getReferenceJobMasters(referenceJobMasterInput: $referenceJobMasterInput) {
      jobNumber
      jobDescription
      customerNumber
      intAddressId
    }
  }
`;
export const jobs = /* GraphQL */ `
  query Jobs(
    $customerId: String!
    $facilityId: String
    $mdDueType: String
    $dueDate: String
    $dataStatus: JobStatus
    $limit: Int
    $pageNumber: Int
  ) {
    jobs(
      customerId: $customerId
      facilityId: $facilityId
      mdDueType: $mdDueType
      dueDate: $dueDate
      dataStatus: $dataStatus
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        jobId
        jobName
        startDate
        stopDate
        dataStatus
        specialInstructions
        draftStep
        draftStatus
        jobType
      }
      metadata {
        totalCount
      }
    }
  }
`;
export const jobSummary = /* GraphQL */ `
  query JobSummary($customerId: String!, $jobId: String!) {
    jobSummary(customerId: $customerId, jobId: $jobId) {
      jobId
      jobName
      specialInstructions
      startDate
      stopDate
      jobType
      customerId
      customerName
      jobCustodian
      dataStatus
      jobFacilities {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
      jobServices {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      jobFrequencies {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      jobVendors {
        jobId
        vendorId
        mdVendorType
      }
      jobDueDates {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      jobAttachments {
        attachmentId
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        dataStatus
        comments
        jobId
        facilityId
        tags
      }
    }
  }
`;
export const jobSummaryV2 = /* GraphQL */ `
  query JobSummaryV2($customerId: String!, $jobId: String!) {
    jobSummaryV2(customerId: $customerId, jobId: $jobId) {
      jobId
      actualId
      jobName
      specialInstructions
      startDate
      stopDate
      jobType
      customerId
      customerName
      jobCustodian
      dataStatus
      jobFacilities {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
      jobServices {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      jobFrequencies {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      jobVendors {
        jobId
        vendorId
        mdVendorType
      }
      jobDueDates {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      jobAttachments {
        attachmentId
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        dataStatus
        comments
        jobId
        facilityId
        tags
      }
      subJobs {
        jobId
        jobName
        jobType
        jobServices {
          jobId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          tasks {
            taskId
            taskName
          }
          actualMdServiceUnits
          actualServiceMeasure
        }
        jobVendors {
          jobId
          vendorId
          mdVendorType
        }
        jobBids {
          bidId
          vendorId
          vendorName
          vendorEmail
          mdFrequencyType
          bidName
          totalCost
          profitMargin
          isFlatMargin
          finalQuote
          comments
          bidEstimationType
          bidCreationDate
          draftStep
          draftStatus
          dataStatus
          bidSubmissionDate
          bidVersionCreatedDate
          bidVersion
        }
      }
      isPaid
      jobRating
    }
  }
`;
export const jobsV2 = /* GraphQL */ `
  query JobsV2(
    $customerId: String!
    $facilityId: String
    $mdDueType: String
    $dueDate: String
    $startDate: String
    $dataStatus: String
    $limit: Int
    $pageNumber: Int
  ) {
    jobsV2(
      customerId: $customerId
      facilityId: $facilityId
      mdDueType: $mdDueType
      dueDate: $dueDate
      startDate: $startDate
      dataStatus: $dataStatus
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        jobId
        actualId
        jobName
        startDate
        stopDate
        dataStatus
        jobType
        specialInstructions
        draftStep
        draftStatus
        createdOn
        createdBy
        modifiedOn
        modifiedBy
        createdByName
        modifiedByName
        bidCount
        jobCustodian
        intId
        allowVendorToReschedule
        jobFacilities {
          facilityId
        }
        jobDueDates {
          jobId
          mdDueType
          dueDescription
          dueDate
        }
        jobBids {
          vendorId
          vendorName
          dataStatus
        }
        jobFrequencies {
          mdShiftType
          mdScheduleType
          mdServiceId
          scheduleDays
          fromTime
          toTime
          cronExpression
          repeatitions
          repetition
        }
        jobAttachments {
          attachmentId
          mdAttachmentType
          fileName
          mdFileContext
          fileExtension
          url
          dataStatus
          comments
          jobId
          facilityId
          tags
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const referenceJobs = /* GraphQL */ `
  query ReferenceJobs($vendorId: String!, $userId: String!) {
    referenceJobs(vendorId: $vendorId, userId: $userId) {
      addressId
      addressLine1
      addressLine2
      addressLine3
      addressName
      latitude
      longitude
      city
      county
      customerId
      facilityId
      intCustomerNumber
      jobDescription
      jobNumber
      mdCountryCode
      postalCode
      regionId
      stateCode
      subRegionId
      userId
      vendorId
      roleId
      unionIndicator
      pti
    }
  }
`;
export const jobServices = /* GraphQL */ `
  query JobServices($customerId: String!, $jobId: String!) {
    jobServices(customerId: $customerId, jobId: $jobId) {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
      }
      actualMdServiceUnits
      actualServiceMeasure
    }
  }
`;
export const getAllJobs = /* GraphQL */ `
  query GetAllJobs(
    $vendorId: String
    $jobId: String
    $searchText: String
    $limit: Int
    $type: JobsQueryType
    $jobDataStatus: String
    $pageNumber: Int
    $dataStatus: String
    $sortBy: String
    $sortOrder: String
  ) {
    getAllJobs(
      vendorId: $vendorId
      jobId: $jobId
      searchText: $searchText
      limit: $limit
      type: $type
      jobDataStatus: $jobDataStatus
      pageNumber: $pageNumber
      dataStatus: $dataStatus
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        bidId
        jobId
        vendorId
        jobName
        jobDataStatus
        specialInstructions
        jobInterestActionType
        tags
        comments
        url
        fileExtension
        fileName
        mdFileContext
        mdAttachmentType
        attachmentId
        mdDueType
        dueDate
        jobServices {
          mdServiceId
          serviceMeasure
          mdServiceUnits
          mdServiceName
        }
        customerId
        customerName
        customerEmail
        jobCustodian
        jobType
        startDate
        endDate
        totalSqftArea
        totalUnitsArea
        facilityId
        mdFacilityType
        facilityAddress
        jobSize
        isPaid
        percentageMatch
        vendorCompanyName
        mdVendorType
        buildingName
        durationOfTheJob
        facilityIconUrl
        logoUrl
        bidCount
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getAllBids = /* GraphQL */ `
  query GetAllBids(
    $vendorId: String
    $jobId: String
    $bidDataStatus: BidStatus
    $jobDataStatus: JobStatus
    $type: BidQueryType
    $limit: Int
    $pageNumber: Int
    $sortBy: String
    $sortOrder: String
    $searchText: String
  ) {
    getAllBids(
      vendorId: $vendorId
      jobId: $jobId
      bidDataStatus: $bidDataStatus
      jobDataStatus: $jobDataStatus
      type: $type
      limit: $limit
      pageNumber: $pageNumber
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchText: $searchText
    ) {
      data {
        jobId
        bidId
        vendorId
        jobName
        startDate
        stopDate
        jobSize
        bidDataStatus
        jobDataStatus
        jobType
        markupPCT
        bidCreatedOn
        bidSubmissionDate
        draftStep
        isFlatMargin
        bidTemplate
        mdFrequencyType
        vendorBidComments
        vendorName
        vendorEmail
        submittedBy
        jobInterestActionType
        jobCustodian
        tags
        comments
        url
        fileExtension
        mdFileContext
        fileName
        mdAttachmentType
        attachmentId
        customerId
        percentageMatch
        facilityAddress
        totalSqftArea
        totalUnitsArea
        specialInstructions
        bidDraftStatus
        mdVendorType
        facilityBuildingName
        facilityIconUrl
        bidVersion
        durationOfTheJob
        finalQuote
        type
        bidDueDate
        jobServices {
          mdServiceId
          serviceMeasure
          mdServiceUnits
          mdServiceName
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getUserNames = /* GraphQL */ `
  query GetUserNames($jobId: String, $vendorIds: String, $userIds: String) {
    getUserNames(jobId: $jobId, vendorIds: $vendorIds, userIds: $userIds) {
      jobCreatorUserName
      vendorUserName
    }
  }
`;
export const userExist = /* GraphQL */ `
  query UserExist($user: String) {
    userExist(user: $user) {
      user {
        status
        userId
        isEnabled
        isEmailVerified
      }
    }
  }
`;
export const user = /* GraphQL */ `
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
    }
  }
`;
export const country = /* GraphQL */ `
  query Country {
    country {
      countryName
      countryCode
      countryLegalName
      currencyCode
      currencySymbol
      callingCode
      capitalCity
      UTCTimezone
      localeCode
      dayLightSavings
      displaySeq
    }
  }
`;
export const state = /* GraphQL */ `
  query State {
    state {
      stateName
      stateCode
      countryCode
      capitalCity
      UTCTimezone
    }
  }
`;
export const masterData = /* GraphQL */ `
  query MasterData(
    $id: String!
    $keyId: String
    $displayName: String
    $limit: Int
    $pageNumber: Int
  ) {
    masterData(
      id: $id
      keyId: $keyId
      displayName: $displayName
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        code
        keyCode
        value
        subKey1Value
        subKey2Value
        canCustomize
        userOrg
        processId
        defaultCode
        keyId
        keyType
        displayName
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const masterDataCategory = /* GraphQL */ `
  query MasterDataCategory($code: String!, $keyCode: String!) {
    masterDataCategory(code: $code, keyCode: $keyCode) {
      code
      keyCode
      value
      subKey1Value
      subKey2Value
      canCustomize
      userOrg
      processId
      defaultCode
      keyId
      keyType
      displayName
    }
  }
`;
export const standardRates = /* GraphQL */ `
  query StandardRates($standardRatesFilterInput: StandardRatesFilterInput!) {
    standardRates(standardRatesFilterInput: $standardRatesFilterInput) {
      standardRateId
      keyId
      keyType
      customerId
      serviceCategoryId
      serviceCategoryName
      mdCountryCode
      regionId
      subregionId
      stateCode
      city
      postalCode
      currency
      unionBlendedRate
      nonUnionBlendedRate
    }
  }
`;
export const serviceCategory = /* GraphQL */ `
  query ServiceCategory {
    serviceCategory {
      serviceCategoryId
      serviceCategory
      taskCategory
      task
      imageUrl
    }
  }
`;
export const serviceCategoryV2 = /* GraphQL */ `
  query ServiceCategoryV2($limit: Int, $pageNumber: Int) {
    serviceCategoryV2(limit: $limit, pageNumber: $pageNumber) {
      data {
        industryId
        industryName
        industryImageUrl
        categoryId
        categoryName
        categoryImageUrl
        taskId
        taskName
        taskImageUrl
        unit
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const serviceCategoryV3 = /* GraphQL */ `
  query ServiceCategoryV3(
    $limit: Int
    $pageNumber: Int
    $orgId: String
    $taskId: String
    $serviceCategoryId: String
  ) {
    serviceCategoryV3(
      limit: $limit
      pageNumber: $pageNumber
      orgId: $orgId
      taskId: $taskId
      serviceCategoryId: $serviceCategoryId
    ) {
      data {
        taskId
        serviceCategoryId
        serviceCategoryName
        categoryImageUrl
        categoryDescription
        orgId
        mdServiceType
        taskName
        taskImageUrl
        taskDescription
        unit
        mdAreaType
        modifiedBy
        createdBy
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const allFeatureFlags = /* GraphQL */ `
  query AllFeatureFlags($filterFeatureFlagInput: FilterFeatureFlagInput) {
    allFeatureFlags(filterFeatureFlagInput: $filterFeatureFlagInput) {
      data {
        keyId
        keyType
        featureName
        featureKey
        description
        isMobileEnabled
        isWebEnabled
        isAdminEnabled
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const recommendedJobs = /* GraphQL */ `
  query RecommendedJobs(
    $vendorId: String!
    $serviceMeasure: [ServiceMeasure]
    $startDate: String
    $endDate: String
    $percentageRange: [RangeInput]
    $jobType: [JobType]
    $scheduleType: [ScheduleType]
    $jobDuration: JobDuration
    $facilityType: [String]
    $dueDate: String
    $includeIgnoredJobs: Boolean
    $limit: Int
    $pageNumber: Int
    $searchText: String
    $actionType: JobInterestsActionType
    $sortBy: String
    $sortOrder: String
  ) {
    recommendedJobs(
      vendorId: $vendorId
      serviceMeasure: $serviceMeasure
      startDate: $startDate
      endDate: $endDate
      percentageRange: $percentageRange
      jobType: $jobType
      scheduleType: $scheduleType
      jobDuration: $jobDuration
      facilityType: $facilityType
      dueDate: $dueDate
      includeIgnoredJobs: $includeIgnoredJobs
      limit: $limit
      pageNumber: $pageNumber
      searchText: $searchText
      actionType: $actionType
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      serviceLocations {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      servicesOffered {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      jobsWithinServiceLocations {
        id
      }
      matchedJobs {
        data {
          jobId
          jobName
          specialInstructions
          startDate
          stopDate
          jobFacility {
            jobId
            facilityId
            mdFacilityType
            facilityAddress
            remarks
            status
            jobStartDate
            jobEndData
          }
          jobSize
          dataStatus
          jobType
          createdOn
          jobServices {
            jobId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            tasks {
              taskId
              taskName
            }
            actualMdServiceUnits
            actualServiceMeasure
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            jobId
            vendorId
            mdVendorType
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          percentageMatch
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedBids {
        bidId
        jobId
        bidName
        totalCost
        profitMargin
        finalQuote
        bidEstimationType
        bidCreationDate
        draftStep
        isFlatMargin
        draftStatus
        dataStatus
        bidTemplate
        bidVersion
        bidVersionCreatedDate
        submittedBy
        bidSubmissionDate
        mdFrequencyType
        comments
        bidCreatedFromSample
        errorCode
        vendorId
        vendorName
        vendorEmail
      }
      matchedFacilities {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
    }
  }
`;
export const allJobs = /* GraphQL */ `
  query AllJobs(
    $dueDate: String
    $vendorId: String!
    $serviceMeasure: [ServiceMeasure]
    $startDate: String
    $endDate: String
    $percentageRange: [RangeInput]
    $jobType: [JobType]
    $scheduleType: [ScheduleType]
    $jobDuration: JobDuration
    $facilityType: [String]
    $searchText: String
    $limit: Int
    $pageNumber: Int
    $actionType: JobInterestsActionType
    $sortBy: String
    $sortOrder: String
  ) {
    allJobs(
      dueDate: $dueDate
      vendorId: $vendorId
      serviceMeasure: $serviceMeasure
      startDate: $startDate
      endDate: $endDate
      percentageRange: $percentageRange
      jobType: $jobType
      scheduleType: $scheduleType
      jobDuration: $jobDuration
      facilityType: $facilityType
      searchText: $searchText
      limit: $limit
      pageNumber: $pageNumber
      actionType: $actionType
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      serviceLocations {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      servicesOffered {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      jobsWithinServiceLocations {
        id
      }
      matchedJobs {
        data {
          jobId
          jobName
          specialInstructions
          startDate
          stopDate
          jobFacility {
            jobId
            facilityId
            mdFacilityType
            facilityAddress
            remarks
            status
            jobStartDate
            jobEndData
          }
          jobSize
          dataStatus
          jobType
          createdOn
          jobServices {
            jobId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            tasks {
              taskId
              taskName
            }
            actualMdServiceUnits
            actualServiceMeasure
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            jobId
            vendorId
            mdVendorType
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          percentageMatch
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedBids {
        bidId
        jobId
        bidName
        totalCost
        profitMargin
        finalQuote
        bidEstimationType
        bidCreationDate
        draftStep
        isFlatMargin
        draftStatus
        dataStatus
        bidTemplate
        bidVersion
        bidVersionCreatedDate
        submittedBy
        bidSubmissionDate
        mdFrequencyType
        comments
        bidCreatedFromSample
        errorCode
        vendorId
        vendorName
        vendorEmail
      }
      matchedFacilities {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
    }
  }
`;
export const allBidsByVendor = /* GraphQL */ `
  query AllBidsByVendor(
    $vendorId: String!
    $serviceMeasure: [ServiceMeasure]
    $startDate: String
    $endDate: String
    $percentageRange: [RangeInput]
    $jobType: [JobType]
    $scheduleType: [ScheduleType]
    $jobDuration: JobDuration
    $facilityType: [String]
    $dueDate: String
    $limit: Int
    $pageNumber: Int
    $searchText: String
    $sortBy: String
    $sortOrder: String
  ) {
    allBidsByVendor(
      vendorId: $vendorId
      serviceMeasure: $serviceMeasure
      startDate: $startDate
      endDate: $endDate
      percentageRange: $percentageRange
      jobType: $jobType
      scheduleType: $scheduleType
      jobDuration: $jobDuration
      facilityType: $facilityType
      dueDate: $dueDate
      limit: $limit
      pageNumber: $pageNumber
      searchText: $searchText
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      serviceLocations {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      servicesOffered {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      jobsWithinServiceLocations {
        id
      }
      matchedJobs {
        data {
          jobId
          jobName
          specialInstructions
          startDate
          stopDate
          jobFacility {
            jobId
            facilityId
            mdFacilityType
            facilityAddress
            remarks
            status
            jobStartDate
            jobEndData
          }
          jobSize
          dataStatus
          jobType
          createdOn
          jobServices {
            jobId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            tasks {
              taskId
              taskName
            }
            actualMdServiceUnits
            actualServiceMeasure
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            jobId
            vendorId
            mdVendorType
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          percentageMatch
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedBids {
        bidId
        jobId
        bidName
        totalCost
        profitMargin
        finalQuote
        bidEstimationType
        bidCreationDate
        draftStep
        isFlatMargin
        draftStatus
        dataStatus
        bidTemplate
        bidVersion
        bidVersionCreatedDate
        submittedBy
        bidSubmissionDate
        mdFrequencyType
        comments
        bidCreatedFromSample
        errorCode
        vendorId
        vendorName
        vendorEmail
      }
      matchedFacilities {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
    }
  }
`;
export const allBidSubmissionsByVendorId = /* GraphQL */ `
  query AllBidSubmissionsByVendorId(
    $vendorId: String!
    $limit: Int
    $pageNumber: Int
  ) {
    allBidSubmissionsByVendorId(
      vendorId: $vendorId
      limit: $limit
      pageNumber: $pageNumber
    ) {
      serviceLocations {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      servicesOffered {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      jobsWithinServiceLocations {
        id
      }
      matchedJobs {
        data {
          jobId
          jobName
          specialInstructions
          startDate
          stopDate
          jobFacility {
            jobId
            facilityId
            mdFacilityType
            facilityAddress
            remarks
            status
            jobStartDate
            jobEndData
          }
          jobSize
          dataStatus
          jobType
          createdOn
          jobServices {
            jobId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            tasks {
              taskId
              taskName
            }
            actualMdServiceUnits
            actualServiceMeasure
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            jobId
            vendorId
            mdVendorType
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          percentageMatch
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedBids {
        bidId
        jobId
        bidName
        totalCost
        profitMargin
        finalQuote
        bidEstimationType
        bidCreationDate
        draftStep
        isFlatMargin
        draftStatus
        dataStatus
        bidTemplate
        bidVersion
        bidVersionCreatedDate
        submittedBy
        bidSubmissionDate
        mdFrequencyType
        comments
        bidCreatedFromSample
        errorCode
        vendorId
        vendorName
        vendorEmail
      }
      matchedFacilities {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
    }
  }
`;
export const matchedVendors = /* GraphQL */ `
  query MatchedVendors(
    $associateId: String!
    $dataStatus: String
    $limit: Int
    $pageNum: Int
  ) {
    matchedVendors(
      associateId: $associateId
      dataStatus: $dataStatus
      limit: $limit
      pageNum: $pageNum
    ) {
      data {
        vendorId
        vendorCompanyName
        doingBusinessAs
        email
        phone
        dataStatus
        address {
          addressName
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          postalCode
          mdCountryCode
        }
        serviceLocations {
          areaName
          locationId
        }
        servicesOffered {
          serviceCategoryId
          serviceSubCategoryId
          serviceDescription
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const zohoDocumentStatus = /* GraphQL */ `
  query ZohoDocumentStatus($requestId: String!) {
    zohoDocumentStatus(requestId: $requestId) {
      request_status
      action_status
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($organizationId: String!) {
    getOrganization(organizationId: $organizationId) {
      organizationId
      organizationName
      dataStatus
      teams {
        teamId
        name
      }
      users {
        userId
      }
      errorCode
    }
  }
`;
export const getOrganizationByUserId = /* GraphQL */ `
  query GetOrganizationByUserId($userId: String!) {
    getOrganizationByUserId(userId: $userId) {
      organizationId
      organizationName
      dataStatus
      teams {
        teamId
        name
      }
      users {
        userId
      }
      errorCode
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($organizationId: String!) {
    getUsers(organizationId: $organizationId) {
      userId
      username
      email
      signedUp
      phoneNumber
      dataStatus
      organizationId
      teamId
      cognitoUserId
      intId
      salutation
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
    }
  }
`;
export const getAllUsers = /* GraphQL */ `
  query GetAllUsers(
    $organizationId: String!
    $roleName: String
    $searchString: String
    $isAssociate: BooleanType
    $limit: Int
    $pageNumber: Int
    $userId: String
    $intId: String
    $sortBy: String
    $sortOrder: String
    $userIdsToExclude: String
  ) {
    getAllUsers(
      organizationId: $organizationId
      roleName: $roleName
      searchString: $searchString
      isAssociate: $isAssociate
      limit: $limit
      pageNumber: $pageNumber
      userId: $userId
      intId: $intId
      sortBy: $sortBy
      sortOrder: $sortOrder
      userIdsToExclude: $userIdsToExclude
    ) {
      data {
        userId
        username
        email
        signedUp
        phoneNumber
        dataStatus
        organizationId
        teamId
        cognitoUserId
        intId
        salutation
        roles {
          roleId
          roleName
          permissions {
            permissionId
            permissionName
            permissionKey
            channel
          }
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getOrgDetailsByUserName = /* GraphQL */ `
  query GetOrgDetailsByUserName {
    getOrgDetailsByUserName {
      userId
      username
      salutation
      organization {
        organizationName
        recStatus
        dataStatus
        organizationId
      }
    }
  }
`;
export const getUserPermissions = /* GraphQL */ `
  query GetUserPermissions($userId: String!) {
    getUserPermissions(userId: $userId) {
      userId
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
    }
  }
`;
export const getOrgRoleAndPermissions = /* GraphQL */ `
  query GetOrgRoleAndPermissions($orgId: String!) {
    getOrgRoleAndPermissions(orgId: $orgId) {
      organizationId
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
    }
  }
`;
export const getVendorTeamJoinRequests = /* GraphQL */ `
  query GetVendorTeamJoinRequests($associateId: String!) {
    getVendorTeamJoinRequests(associateId: $associateId) {
      dataStatus
      organization {
        organizationId
        organizationName
      }
    }
  }
`;
export const getTags = /* GraphQL */ `
  query GetTags(
    $owner: String
    $tagsText: String
    $jobId: String
    $family: Family!
    $includeSystemTags: String
  ) {
    getTags(
      owner: $owner
      tagsText: $tagsText
      jobId: $jobId
      family: $family
      includeSystemTags: $includeSystemTags
    ) {
      owner
      type
      value
      id
      family
    }
  }
`;
export const getUserManagers = /* GraphQL */ `
  query GetUserManagers(
    $userId: String
    $managerId: String
    $dataStatus: String
    $searchString: String
    $isAssociate: BooleanType
    $limit: Int
    $pageNumber: Int
    $roleName: String
    $regionId: String
    $sortBy: String
    $sortOrder: String
    $userIdsToExclude: String
  ) {
    getUserManagers(
      userId: $userId
      managerId: $managerId
      dataStatus: $dataStatus
      searchString: $searchString
      isAssociate: $isAssociate
      limit: $limit
      pageNumber: $pageNumber
      roleName: $roleName
      regionId: $regionId
      sortBy: $sortBy
      sortOrder: $sortOrder
      userIdsToExclude: $userIdsToExclude
    ) {
      data {
        userId
        managerId
        dataStatus
        regionId
        manager {
          managerName
          email
          phoneNumber
          roles {
            roleId
            roleName
          }
          cognitoUserId
          signedUp
        }
        user {
          userName
          email
          phoneNumber
          roles {
            roleId
            roleName
          }
          cognitoUserId
          signedUp
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getUserScopes = /* GraphQL */ `
  query GetUserScopes($userId: String, $facilityId: String) {
    getUserScopes(userId: $userId, facilityId: $facilityId) {
      userId
      errorCode
      canCreateOneTimeJob
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
      userScopes {
        roleId
        customerId
        mdCountryCode
        regionId
        subregionId
        facilityId
      }
    }
  }
`;
export const getRegions = /* GraphQL */ `
  query GetRegions($organizationId: String!) {
    getRegions(organizationId: $organizationId) {
      regionId
      regionName
      organizationId
      parentRegionId
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($regionId: String!) {
    getRegion(regionId: $regionId) {
      regionId
      regionName
      organizationId
      parentRegionId
    }
  }
`;
export const getUserRoleScopes = /* GraphQL */ `
  query GetUserRoleScopes($getUserRoleScopesInput: GetUserRoleScopesInput) {
    getUserRoleScopes(getUserRoleScopesInput: $getUserRoleScopesInput) {
      data {
        userRoleScopeId
        keyId
        keyType
        userId
        organizationId
        roleId
        customerId
        mdCountryCode
        regionId
        subRegionId
        facilityId
        userRoleId
        keyName
        userName
        organizationName
        roleName
        customerName
        regionName
        subRegionName
        facilityName
        createdByName
        modifiedByName
        intAddressId
        jobDescription
        jobNumber
        customerNumber
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getUserRoleScope = /* GraphQL */ `
  query GetUserRoleScope($userRoleScopeId: String!) {
    getUserRoleScope(userRoleScopeId: $userRoleScopeId) {
      userRoleScopeId
      keyId
      keyType
      userId
      organizationId
      roleId
      customerId
      mdCountryCode
      regionId
      subRegionId
      facilityId
      userRoleId
      keyName
      userName
      organizationName
      roleName
      customerName
      regionName
      subRegionName
      facilityName
      createdByName
      modifiedByName
      intAddressId
      jobDescription
      jobNumber
      customerNumber
    }
  }
`;
export const getCustomerByRegion = /* GraphQL */ `
  query GetCustomerByRegion(
    $regionId: String!
    $customerByRegionInput: CustomerByRegionInput
  ) {
    getCustomerByRegion(
      regionId: $regionId
      customerByRegionInput: $customerByRegionInput
    ) {
      customerId
      customerName
      intId
      customerNumber
      regionId
      regionName
    }
  }
`;
export const emailTransaction = /* GraphQL */ `
  query EmailTransaction($emailTransactionId: String!) {
    emailTransaction(emailTransactionId: $emailTransactionId) {
      emailTransactionId
      processName
      IPAddress
      transactionStatus
      createdBy
      jobId
      bidId
      mdVendorType
      isReadOnly
    }
  }
`;
export const bidSubmissionsByVendorId = /* GraphQL */ `
  query BidSubmissionsByVendorId(
    $vendorId: String!
    $limit: Int
    $pageNumber: Int
    $sortBy: String
    $sortOrder: String
  ) {
    bidSubmissionsByVendorId(
      vendorId: $vendorId
      limit: $limit
      pageNumber: $pageNumber
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      serviceLocations {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      servicesOffered {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      jobsWithinServiceLocations {
        id
      }
      matchedJobs {
        data {
          jobId
          jobName
          specialInstructions
          startDate
          stopDate
          jobFacility {
            jobId
            facilityId
            mdFacilityType
            facilityAddress
            remarks
            status
            jobStartDate
            jobEndData
          }
          jobSize
          dataStatus
          jobType
          createdOn
          jobServices {
            jobId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            tasks {
              taskId
              taskName
            }
            actualMdServiceUnits
            actualServiceMeasure
          }
          jobFrequencies {
            mdShiftType
            mdScheduleType
            mdServiceId
            scheduleDays
            fromTime
            toTime
            cronExpression
            repeatitions
            repetition
          }
          jobVendors {
            jobId
            vendorId
            mdVendorType
          }
          jobDueDates {
            jobId
            mdDueType
            dueDescription
            dueDate
          }
          percentageMatch
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedBids {
        data {
          bidId
          jobId
          bidName
          totalCost
          profitMargin
          finalQuote
          bidEstimationType
          bidCreationDate
          draftStep
          isFlatMargin
          draftStatus
          dataStatus
          bidTemplate
          bidVersion
          bidVersionCreatedDate
          submittedBy
          bidSubmissionDate
          mdFrequencyType
          comments
          bidCreatedFromSample
          errorCode
          vendorId
          vendorName
          vendorEmail
        }
        metadata {
          totalCount
          pageIndex
          limit
        }
      }
      matchedFacilities {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
    }
  }
`;
export const getStandardRatesAndPtiTaxes = /* GraphQL */ `
  query GetStandardRatesAndPtiTaxes(
    $standardRatesFilterInput: StandardRatesFilterInput!
  ) {
    getStandardRatesAndPtiTaxes(
      standardRatesFilterInput: $standardRatesFilterInput
    ) {
      standardRates {
        standardRateId
        keyId
        keyType
        customerId
        serviceCategoryId
        serviceCategoryName
        mdCountryCode
        regionId
        subregionId
        stateCode
        city
        postalCode
        currency
        unionBlendedRate
        nonUnionBlendedRate
      }
      ptiTaxes {
        recordId
        keyId
        keyType
        ptiPercentage
        stateCode
        nonUnionPtiPercentage
        unionPtiPercentage
      }
    }
  }
`;
export const getUserByCognitoUserId = /* GraphQL */ `
  query GetUserByCognitoUserId($cognitoUserId: String) {
    getUserByCognitoUserId(cognitoUserId: $cognitoUserId) {
      userId
      username
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($organizationId: String!, $userId: String!) {
    getUser(organizationId: $organizationId, userId: $userId) {
      userId
      username
      email
      signedUp
      phoneNumber
      dataStatus
      organizationId
      teamId
      cognitoUserId
      intId
      errorCode
      salutation
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
    }
  }
`;
export const getJobsByQueryType = /* GraphQL */ `
  query GetJobsByQueryType(
    $vendorId: String!
    $serviceMeasure: [ServiceMeasure]
    $startDate: String
    $endDate: String
    $percentageRange: [RangeInput]
    $jobType: [JobType]
    $scheduleType: [ScheduleType]
    $jobDuration: JobDuration
    $facilityType: [String]
    $dueDate: String
    $includeIgnoredJobs: Boolean
    $limit: Int
    $pageNumber: Int
    $searchText: String
    $actionType: JobInterestsActionType
    $sortBy: String
    $sortOrder: String
    $queryType: QueryType!
  ) {
    getJobsByQueryType(
      vendorId: $vendorId
      serviceMeasure: $serviceMeasure
      startDate: $startDate
      endDate: $endDate
      percentageRange: $percentageRange
      jobType: $jobType
      scheduleType: $scheduleType
      jobDuration: $jobDuration
      facilityType: $facilityType
      dueDate: $dueDate
      includeIgnoredJobs: $includeIgnoredJobs
      limit: $limit
      pageNumber: $pageNumber
      searchText: $searchText
      actionType: $actionType
      sortBy: $sortBy
      sortOrder: $sortOrder
      queryType: $queryType
    ) {
      data {
        bidId
        bidDueDate
        logoUrl
        address {
          callHours
          transportationName
          storageName
          callStartTime
          callEndTime
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
        facilityName
        finalQuote
        jobId
        jobName
        type
        specialInstructions
        startDate
        stopDate
        jobFacility {
          jobId
          facilityId
          mdFacilityType
          facilityAddress
          remarks
          status
          jobStartDate
          jobEndData
        }
        jobSize
        dataStatus
        jobType
        createdOn
        jobServices {
          jobId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          tasks {
            taskId
            taskName
          }
          actualMdServiceUnits
          actualServiceMeasure
        }
        jobFrequencies {
          mdShiftType
          mdScheduleType
          mdServiceId
          scheduleDays
          fromTime
          toTime
          cronExpression
          repeatitions
          repetition
        }
        jobVendors {
          jobId
          vendorId
          mdVendorType
        }
        jobDueDates {
          jobId
          mdDueType
          dueDescription
          dueDate
        }
        percentageMatch
        bidVersion
        bidStatus
        bidComments {
          commentId
          comment
          commentBy
          commentByName
          commenterRoleId
          commenterRoleName
          entityType
          organizationId
          commentDate
          vendorBidId
          jobId
          bidVersion
          actionType
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const vendorTeamInterestedAssociateList = /* GraphQL */ `
  query VendorTeamInterestedAssociateList($organizationId: String!) {
    vendorTeamInterestedAssociateList(organizationId: $organizationId) {
      associateId
      title
      name
      emergencyContactNumber
      logoUrl
      availableForNightShifts
      isFavorite
      transportationType
      priorExperience
      supervisorExperience
      age
      availableForWeekends
      emailId
      associateAddress {
        postalCode
      }
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails($userDetailsInput: UserDetailsInput) {
    getUserDetails(userDetailsInput: $userDetailsInput) {
      userId
      username
      email
      signedUp
      phoneNumber
      dataStatus
      organizationId
      teamId
      cognitoUserId
      intId
      salutation
      roles {
        roleId
        roleName
        permissions {
          permissionId
          permissionName
          permissionKey
          channel
        }
      }
    }
  }
`;
export const findAllInspection = /* GraphQL */ `
  query FindAllInspection($findAllInspectionInput: FindAllInspectionInput) {
    findAllInspection(findAllInspectionInput: $findAllInspectionInput) {
      data {
        inspectionId
        recSeq
        recStatus
        orgId
        facilityId
        jobId
        inspectorId
        inspectionName
        inspectionDescription
        inspectionEmailRecipient
        inspectionReportUrl
        frequencyType
        inspectionStartDate
        inspectionEndDate
        plannedInspectionStartDate
        plannedInspectionEndDate
        facilityName
        facilityAddress
        facilityType
        vendorName
        jobName
        intId
        latitude
        longitude
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const findAllInspectionSections = /* GraphQL */ `
  query FindAllInspectionSections($inspectionId: String!) {
    findAllInspectionSections(inspectionId: $inspectionId) {
      sectionId
      recSeq
      recStatus
      inspectionId
      sectionName
      sectionDescription
      displaySeq
      isProofRequired
      proofDescription
      questions {
        questionId
        recSeq
        recStatus
        inspectionId
        sectionId
        questionText
        questionDescription
        questionNumber
        answerType
        isMandatory
        answerPattern
        parentQuestionId
        optionId
        answerOptions {
          answerId
          recSeq
          recStatus
          questionId
          displayText
          displaySeq
          answerDescription
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      mandatoryQuestionCount
      nonMandatoryQuestionCount
      answeredMandatoryQuestionCount
      answeredNonMandatoryQuestionCount
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const findAllInspectionQuestions = /* GraphQL */ `
  query FindAllInspectionQuestions($sectionId: String!) {
    findAllInspectionQuestions(sectionId: $sectionId) {
      inspectionFormQuestions {
        id
        name
        type
        question
        questionDescription
        requiredMessage
        invalidInputMessage
        isVisible
        multiline
        value
        placeholder
        pattern
        rangeStartDate
        rangeEndDate
        dropdownListPrimaryButtonName
        isMandatory
        hasQuestion
        parentId
        optionId
        isChild
        isParent
        options {
          label
          id
          value
          name
          description
        }
        label
        isOptionsSorted
        preFilled
        preFilledObj {
          label
          id
          value
          name
          description
        }
        preFilledArr {
          label
          id
          value
          name
          description
        }
        isRadioSelection
        isCheckBoxSelection
      }
      inspectionAttachments {
        recordId
        inspectionId
        sectionId
        fileName
        url
      }
    }
  }
`;
export const findInspectionReportUrl = /* GraphQL */ `
  query FindInspectionReportUrl {
    findInspectionReportUrl
  }
`;
export const getInspectionLocations = /* GraphQL */ `
  query GetInspectionLocations(
    $inspectionLocationsInput: InspectionLocationsInput!
  ) {
    getInspectionLocations(
      inspectionLocationsInput: $inspectionLocationsInput
    ) {
      data {
        recordId
        inspectorId
        facilityId
        inspectionType
        mdFacilityType
        facilityName
        facilityAddress
        facilityIconUrl
        latitude
        longitude
        logoUrl
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getInspectionTypes = /* GraphQL */ `
  query GetInspectionTypes($inspectionTypesInput: InspectionTypesInput!) {
    getInspectionTypes(inspectionTypesInput: $inspectionTypesInput) {
      data {
        inspectorId
        orgId
        inspectionType
        inspectionTypeName
        inspectionTypeDescription
        templateId
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const allTaskApproval = /* GraphQL */ `
  query AllTaskApproval($taskId: String!) {
    allTaskApproval(taskId: $taskId) {
      approvalId
      approvalName
      dueDate
      startDate
      completionDate
      reason
      status
      rankingRate
      approvalStatus
      mdApproverType
    }
  }
`;
export const workOrderTaskAttachment = /* GraphQL */ `
  query WorkOrderTaskAttachment($taskId: String!) {
    workOrderTaskAttachment(taskId: $taskId) {
      attachmentId
      attachmentStatus
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      fileHash
      comments
      createdById
      createdByName
    }
  }
`;
export const fetchIssueTemplate = /* GraphQL */ `
  query FetchIssueTemplate($templateType: IssueType) {
    fetchIssueTemplate(templateType: $templateType) {
      templateId
      name
      templateType
      fieldConfiguration
      version
    }
  }
`;
export const allPreShiftChecklistTemplate = /* GraphQL */ `
  query AllPreShiftChecklistTemplate($workOrderId: String!) {
    allPreShiftChecklistTemplate(workOrderId: $workOrderId) {
      preShiftChecklistTemplateId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      preShiftChecklistTemplateItems {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
      }
    }
  }
`;
export const preShiftChecklistTemplate = /* GraphQL */ `
  query PreShiftChecklistTemplate(
    $workOrderId: String!
    $preShiftChecklistTemplateId: String!
  ) {
    preShiftChecklistTemplate(
      workOrderId: $workOrderId
      preShiftChecklistTemplateId: $preShiftChecklistTemplateId
    ) {
      preShiftChecklistTemplateId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      preShiftChecklistTemplateItems {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
      }
    }
  }
`;
export const preShiftChecklistTemplates = /* GraphQL */ `
  query PreShiftChecklistTemplates(
    $workOrderId: String!
    $woScheduleMasterId: String!
  ) {
    preShiftChecklistTemplates(
      workOrderId: $workOrderId
      woScheduleMasterId: $woScheduleMasterId
    ) {
      preShiftChecklistTemplateId
      woScheduleMasterId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      preShiftChecklistTemplateItems {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
      }
    }
  }
`;
export const preShiftChecklist = /* GraphQL */ `
  query PreShiftChecklist(
    $preShiftChecklistTemplateId: String!
    $preShiftChecklistId: String!
  ) {
    preShiftChecklist(
      preShiftChecklistTemplateId: $preShiftChecklistTemplateId
      preShiftChecklistId: $preShiftChecklistId
    ) {
      executionDate
      workDayId
      preShiftChecklistId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      preShiftChecklistTemplateId
      preShiftChecklistItems {
        itemName
        mdItemActionType
        quantity
        mdQuantityType
        workOrderServiceId
        markedAsDone
        markedBy
        markedOn
        preShiftChecklistId
        preShiftChecklistItemId
        preShiftChecklistTemplateItemId
        preShiftChecklistIssueId
      }
    }
  }
`;
export const allPreShiftChecklist = /* GraphQL */ `
  query AllPreShiftChecklist($workDayId: String!) {
    allPreShiftChecklist(workDayId: $workDayId) {
      executionDate
      workDayId
      preShiftChecklistId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      preShiftChecklistTemplateId
      preShiftChecklistItems {
        itemName
        mdItemActionType
        quantity
        mdQuantityType
        workOrderServiceId
        markedAsDone
        markedBy
        markedOn
        preShiftChecklistId
        preShiftChecklistItemId
        preShiftChecklistTemplateItemId
        preShiftChecklistIssueId
      }
    }
  }
`;
export const allPunchInOutByWorkDay = /* GraphQL */ `
  query AllPunchInOutByWorkDay($workDayId: String!, $userId: String) {
    allPunchInOutByWorkDay(workDayId: $workDayId, userId: $userId) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const latestPunchInOutByWorkDay = /* GraphQL */ `
  query LatestPunchInOutByWorkDay(
    $workDayId: String!
    $userId: String
    $showRemovedAssociatePunches: Boolean
  ) {
    latestPunchInOutByWorkDay(
      workDayId: $workDayId
      userId: $userId
      showRemovedAssociatePunches: $showRemovedAssociatePunches
    ) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const allShiftDetails = /* GraphQL */ `
  query AllShiftDetails($workOrderId: String!) {
    allShiftDetails(workOrderId: $workOrderId) {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleStartDate
      scheduleName
      scheduleEndDate
      endDateOffset
      mdScheduleType
      mdShiftType
      scheduleDays
      mdServiceId
      workOrder {
        jobStartDate
        jobEndDate
        actualStartDate
        actualEndDate
      }
    }
  }
`;
export const shiftDetails = /* GraphQL */ `
  query ShiftDetails($shiftDetailId: String!) {
    shiftDetails(shiftDetailId: $shiftDetailId) {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleStartDate
      scheduleName
      scheduleEndDate
      endDateOffset
      mdScheduleType
      mdShiftType
      scheduleDays
      mdServiceId
      workOrder {
        jobStartDate
        jobEndDate
        actualStartDate
        actualEndDate
      }
    }
  }
`;
export const tasks = /* GraphQL */ `
  query Tasks(
    $workOrderId: String
    $Status: String
    $facilityId: String
    $associateId: String
    $workDate: String
    $serviceId: String
    $fromDate: String
    $toDate: String
  ) {
    tasks(
      workOrderId: $workOrderId
      Status: $Status
      facilityId: $facilityId
      associateId: $associateId
      workDate: $workDate
      serviceId: $serviceId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      taskId
      occurrenceDate
      suggestedReworkDate
      suggestedScheduleType
      taskNumber
      markedAsDone
      markedBy
      markedOn
      taskStatus
      workOrderId
      workDayId
      taskScheduleId
      mdServiceDescription
      plannedStartTime
      plannedEndTime
      plannedEffort
      actualEffort
      actualStartTime
      actualEndTime
      associateId
      taskSchedule {
        taskScheduleId
        mdShiftType
        isProofOfCompletionRequired
        schedule
        duration
        mdTaskId
        noOfOccurrences
        noOfOccurrencesCompleted
      }
      taskAssignment {
        taskId
        taskAssignmentId
        assignedBy
        assignedOn
        associateId
      }
      reworkTaskId
      errorCode
    }
  }
`;
export const tasksV2 = /* GraphQL */ `
  query TasksV2(
    $workOrderId: String
    $workDayId: String
    $status: String
    $facilityId: String
    $associateId: String
    $workDate: String
    $mdTaskId: String
    $fromDate: String
    $toDate: String
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
  ) {
    tasksV2(
      workOrderId: $workOrderId
      workDayId: $workDayId
      status: $status
      facilityId: $facilityId
      associateId: $associateId
      workDate: $workDate
      mdTaskId: $mdTaskId
      fromDate: $fromDate
      toDate: $toDate
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
    ) {
      mdServiceType
      serviceCategoryName
      categoryDescription
      createdBy
      mdAreaType
      orgId
      mdServiceId
      categoryImageUrl
      tasks {
        taskDescription
        taskId
        taskNumber
        mdTaskId
        taskName
        unit
        isProofOfCompletionRequired
        status
        actualStartTime
        actualEndTime
        duration
        associate
        associateId
        salutation
        workDayId
        workOrderId
        markedAsDone
        markedBy
        markedOn
        reworkTaskId
        occurrenceDate
        suggestedReworkDate
        suggestedScheduleType
        taskAttachments {
          attachmentId
          attachmentStatus
          mdAttachmentType
          fileName
          mdFileContext
          fileExtension
          url
          fileHash
          comments
          taskId
        }
        nextAvailableFiveWorkdays {
          workDate
          workDayId
        }
        nextAvailableWorkDay {
          workDate
          workDayId
        }
        isPartiallyCompleted
        isCurrentShiftAvailable
        reworkTasks {
          taskId
          taskNumber
          occurrenceDate
          suggestedReworkDate
          suggestedScheduleType
          markedAsDone
          markedBy
          reworkTaskId
          markedOn
          facilityId
          facilityAreaServiceId
          taskStatus
          mdServiceDescription
          plannedStartTime
          plannedEndTime
          actualEffort
          actualStartTime
          actualEndTime
          associateId
          workOrderId
          workDayId
          taskScheduleId
          taskSagaId
          mdTaskId
          taskName
          taskDescription
          taskImageUrl
          ISSACode
          rate
          unit
          isPartiallyCompleted
          taskSchedule {
            mdTaskId
            noOfOccurrences
            taskScheduleId
            isProofOfCompletionRequired
            duration
          }
          taskAssignment {
            taskId
            assignedBy
            associateId
            assignedOn
          }
          taskAttachment {
            comments
            attachmentId
            attachmentStatus
            mdAttachmentType
            fileName
            mdFileContext
            fileExtension
            url
            fileHash
            dataStatus
          }
          approvalSaga {
            approvalType
            actionedOn
            actionedById
            actionedByRole {
              roleId
              roleName
            }
            comment
            taskId
            approverType
            actionedByName
            salutation
          }
          attachmentLinks {
            attachmentId
            link
          }
        }
        createdOn
        modifiedOn
        approvalSaga {
          approvalType
          actionedOn
          actionedById
          actionedByRole {
            roleId
            roleName
          }
          comment
          taskId
          approverType
          actionedByName
          salutation
        }
        taskAttachmentSaga {
          attachmentId
          attachmentStatus
          mdAttachmentType
          fileName
          mdFileContext
          fileExtension
          url
          fileHash
          comments
          taskId
        }
      }
    }
  }
`;
export const workOrderTeam = /* GraphQL */ `
  query WorkOrderTeam(
    $workOrderId: String!
    $workDayId: String
    $woScheduleMasterId: String
    $taskId: String
    $fromDate: String
    $toDate: String
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
  ) {
    workOrderTeam(
      workOrderId: $workOrderId
      workDayId: $workDayId
      woScheduleMasterId: $woScheduleMasterId
      taskId: $taskId
      fromDate: $fromDate
      toDate: $toDate
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
    ) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      woScheduleMasterId
      taskAssignments {
        taskId
        taskAssignmentId
        assignedBy
        assignedOn
        associateId
      }
      associateStartDate
      associateEndDate
      cognitoUserId
      workDayId
      noShow
      cantMakeIt
      jobAccepted
      workDayTeam {
        workOrderId
        workDayId
        facilityId
        associateId
      }
      username
      roleName
    }
  }
`;
export const scheduleAssignments = /* GraphQL */ `
  query ScheduleAssignments(
    $workOrderId: String!
    $woScheduleMasterId: String!
    $workDayId: String
    $taskId: String
    $fromDate: String
    $toDate: String
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
  ) {
    scheduleAssignments(
      workOrderId: $workOrderId
      woScheduleMasterId: $woScheduleMasterId
      workDayId: $workDayId
      taskId: $taskId
      fromDate: $fromDate
      toDate: $toDate
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
    ) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      woScheduleMasterId
      taskAssignments {
        taskId
        taskAssignmentId
        assignedBy
        assignedOn
        associateId
      }
      associateStartDate
      associateEndDate
      cognitoUserId
      workDayId
      noShow
      cantMakeIt
      jobAccepted
      workDayTeam {
        workOrderId
        workDayId
        facilityId
        associateId
      }
      username
      roleName
    }
  }
`;
export const getWorkOrderTeam = /* GraphQL */ `
  query GetWorkOrderTeam($workOrderId: String!) {
    getWorkOrderTeam(workOrderId: $workOrderId) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
    }
  }
`;
export const allJobShiftDetailsByVendorWorkDay = /* GraphQL */ `
  query AllJobShiftDetailsByVendorWorkDay(
    $vendorId: String!
    $userId: String
    $workDate: String!
    $facilityId: String
    $isJobAccepted: String
    $workOrderStatus: WorkOrderStatus
  ) {
    allJobShiftDetailsByVendorWorkDay(
      vendorId: $vendorId
      userId: $userId
      workDate: $workDate
      facilityId: $facilityId
      isJobAccepted: $isJobAccepted
      workOrderStatus: $workOrderStatus
    ) {
      vendorId
      teamId
      userId
      workOrder {
        workOrderId
        customerId
        vendorId
        jobId
        status
        jobBidSummary {
          jobName
          startDate
          stopDate
        }
        shiftDetails {
          startTime
          endTime
        }
        facilities {
          facilityId
        }
        workDay {
          workDate
          workDayId
          tasks {
            taskId
            taskScheduleId
            occurrenceDate
            markedAsDone
            taskStatus
          }
          punchCard {
            punchTime
            punchType
          }
        }
      }
    }
  }
`;
export const allJobShiftDetailsByCustomerWorkDay = /* GraphQL */ `
  query AllJobShiftDetailsByCustomerWorkDay(
    $workDate: String!
    $customerId: String!
    $facilityId: String
    $isJobAccepted: String
    $workOrderStatus: WorkOrderStatus
  ) {
    allJobShiftDetailsByCustomerWorkDay(
      workDate: $workDate
      customerId: $customerId
      facilityId: $facilityId
      isJobAccepted: $isJobAccepted
      workOrderStatus: $workOrderStatus
    ) {
      workOrderId
      customerId
      vendorId
      jobId
      status
      workOrderTeams {
        vendorId
        teamId
        userId
      }
      jobBidSummary {
        jobName
        startDate
        stopDate
      }
      shiftDetails {
        startTime
        endTime
      }
      facilities {
        facilityId
      }
      workDay {
        workDate
        workDayId
        tasks {
          taskId
          taskScheduleId
          occurrenceDate
          markedAsDone
          taskStatus
        }
        punchCard {
          punchTime
          punchType
        }
      }
    }
  }
`;
export const allJobSCompletedJobShiftByVendor = /* GraphQL */ `
  query AllJobSCompletedJobShiftByVendor($vendorId: String!) {
    allJobSCompletedJobShiftByVendor(vendorId: $vendorId) {
      vendorId
      userId
      roleId
      workOrder {
        workOrderId
        customerId
        shiftDetails {
          startTime
          endTime
        }
        facilities {
          facilityId
        }
      }
    }
  }
`;
export const allShifts = /* GraphQL */ `
  query AllShifts(
    $fromDate: String
    $toDate: String
    $status: String
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
    $limit: Int
    $pageNumber: Int
    $workDayTeamStatus: WorkDayTeamStatus
    $searchText: String
    $jobType: String
    $includePastOTJs: BooleanType
  ) {
    allShifts(
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
      limit: $limit
      pageNumber: $pageNumber
      workDayTeamStatus: $workDayTeamStatus
      searchText: $searchText
      jobType: $jobType
      includePastOTJs: $includePastOTJs
    ) {
      data {
        workDayId
        workOrderName
        vendorId
        vendorName
        facilityId
        facilityName
        facilityAddress
        customerId
        jobStartTime
        jobEndTime
        jobStartDate
        parentWorkOrderId
        hasChild
        jobEndDate
        workOrderId
        workDate
        plannedStartTime
        plannedEndTime
        supervisorIds
        jobId
        latestPunchRecord {
          punchTime
          punchType
        }
        shiftDetails {
          shiftDetailId
          startTime
          endTime
          punchInGraceMinutes
          punchOutGraceMinutes
        }
        workDayTeam {
          workDayId
          dataStatus
          associateId
          completedOn
        }
        jobType
        hasReworkTasks
        jobCustodian
        bidId
        services {
          workOrderServiceId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          taskSchedule {
            taskScheduleId
            mdTaskId
            isProofOfCompletionRequired
          }
        }
        isReworkDay
        isShiftRescheduled
        shiftStartDateTime
        shiftEndDateTime
        woScheduleMasterId
        mdVendorType
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const allUpcomingJobsForAssociateWithPagination = /* GraphQL */ `
  query AllUpcomingJobsForAssociateWithPagination(
    $fromDate: String
    $toDate: String
    $status: WorkOrderStatus
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
    $limit: Int
    $pageNumber: Int
  ) {
    allUpcomingJobsForAssociateWithPagination(
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        workDayId
        workOrderName
        vendorId
        vendorName
        facilityId
        facilityName
        facilityAddress
        customerId
        jobStartTime
        jobEndTime
        jobStartDate
        parentWorkOrderId
        hasChild
        jobEndDate
        workOrderId
        workDate
        plannedStartTime
        plannedEndTime
        supervisorIds
        jobId
        latestPunchRecord {
          punchTime
          punchType
        }
        shiftDetails {
          shiftDetailId
          startTime
          endTime
          punchInGraceMinutes
          punchOutGraceMinutes
        }
        workDayTeam {
          workDayId
          dataStatus
          associateId
          completedOn
        }
        jobType
        hasReworkTasks
        jobCustodian
        bidId
        services {
          workOrderServiceId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          taskSchedule {
            taskScheduleId
            mdTaskId
            isProofOfCompletionRequired
          }
        }
        isReworkDay
        isShiftRescheduled
        shiftStartDateTime
        shiftEndDateTime
        woScheduleMasterId
        mdVendorType
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const allUpcomingJobsForAssociate = /* GraphQL */ `
  query AllUpcomingJobsForAssociate(
    $fromDate: String
    $toDate: String
    $status: WorkOrderStatus
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
    $pageSize: Int
    $workDayTeamStatus: WorkDayTeamStatus
  ) {
    allUpcomingJobsForAssociate(
      fromDate: $fromDate
      toDate: $toDate
      status: $status
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
      pageSize: $pageSize
      workDayTeamStatus: $workDayTeamStatus
    ) {
      workDayId
      workOrderName
      vendorId
      vendorName
      facilityId
      facilityName
      facilityAddress
      customerId
      jobStartTime
      jobEndTime
      jobStartDate
      parentWorkOrderId
      hasChild
      jobEndDate
      workOrderId
      workDate
      plannedStartTime
      plannedEndTime
      supervisorIds
      jobId
      latestPunchRecord {
        punchTime
        punchType
      }
      shiftDetails {
        shiftDetailId
        startTime
        endTime
        punchInGraceMinutes
        punchOutGraceMinutes
      }
      workDayTeam {
        workDayId
        dataStatus
        associateId
        completedOn
      }
      jobType
      hasReworkTasks
      jobCustodian
      bidId
      services {
        workOrderServiceId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        taskSchedule {
          taskScheduleId
          mdTaskId
          isProofOfCompletionRequired
        }
      }
      isReworkDay
      isShiftRescheduled
      shiftStartDateTime
      shiftEndDateTime
      woScheduleMasterId
      mdVendorType
    }
  }
`;
export const availableWorkDayDates = /* GraphQL */ `
  query AvailableWorkDayDates($workOrderId: String!, $workDayId: String) {
    availableWorkDayDates(workOrderId: $workOrderId, workDayId: $workDayId) {
      workDayId
      workDate
      availableDates
      associates {
        associateId
        availableDates
      }
      errorCode
      isReschedulable
    }
  }
`;
export const workOrderFacilityAccess = /* GraphQL */ `
  query WorkOrderFacilityAccess(
    $workOrderId: String!
    $workOrderFacilityAccessId: String!
  ) {
    workOrderFacilityAccess(
      workOrderId: $workOrderId
      workOrderFacilityAccessId: $workOrderFacilityAccessId
    ) {
      facilityId
      facilityName
      facilityAddress
      accessContactPerson
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      accessFrom
      accessTo
      from
      to
      workOrderFacilityAccessId
    }
  }
`;
export const allUpcomingJobsByVendorId = /* GraphQL */ `
  query AllUpcomingJobsByVendorId(
    $vendorId: String
    $customerId: String
    $status: String
    $isJobAccepted: Boolean
    $workOrderId: String
    $userId: String
    $facilityId: String
    $jobStartDate: String
    $jobEndDate: String
    $pageNumber: Int
    $jobId: String
    $limit: Int
    $punchInGraceMinutes: Int
    $punchOutGraceMinutes: Int
    $jobCreatedBy: String
    $jobCreatorRoleName: String
    $sortBy: String
    $sortOrder: String
    $searchText: String
  ) {
    allUpcomingJobsByVendorId(
      vendorId: $vendorId
      customerId: $customerId
      status: $status
      isJobAccepted: $isJobAccepted
      workOrderId: $workOrderId
      userId: $userId
      facilityId: $facilityId
      jobStartDate: $jobStartDate
      jobEndDate: $jobEndDate
      pageNumber: $pageNumber
      jobId: $jobId
      limit: $limit
      punchInGraceMinutes: $punchInGraceMinutes
      punchOutGraceMinutes: $punchOutGraceMinutes
      jobCreatedBy: $jobCreatedBy
      jobCreatorRoleName: $jobCreatorRoleName
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchText: $searchText
    ) {
      data {
        workOrderId
        jobId
        bidId
        vendorId
        customerId
        name
        status
        totalAmount
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        jobStartDate
        jobType
        jobEndDate
        actualStartDate
        actualEndDate
        jobCustodian
        jobOrderAcceptedOn
        jobOrderAcceptedBy
        jobOrderFilePath
        paymentMethod
        paymentFrequency
        paymentDay
        facilities {
          facilityId
          facilityName
          facilityAddress
          accessContactPerson
          accessContactDetails
          otherInformation
          accessInfo
          accessCode
          day
          accessFrom
          accessTo
          from
          to
          workOrderFacilityAccessId
        }
        services {
          workOrderServiceId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          taskSchedule {
            taskScheduleId
            mdTaskId
            isProofOfCompletionRequired
          }
        }
        jobBidSummary {
          jobBidSummaryId
          jobDetails
          bidDetails
        }
        workOrderTeams {
          teamId
          vendorId
          userId
          roleId
          isSupervisor
          workOrderId
          jobAccepted
          associateStartDate
          associateEndDate
          woScheduleMasterId
        }
        shiftDetails {
          shiftDetailId
          startTime
          endTime
          punchInGraceMinutes
          punchOutGraceMinutes
        }
        subWorkOrders {
          workOrderId
          jobId
          bidId
          vendorId
          customerId
          name
          status
          totalAmount
          createdBy
          createdOn
          modifiedBy
          modifiedOn
          jobStartDate
          jobType
          jobEndDate
          actualStartDate
          actualEndDate
          jobCustodian
          jobOrderAcceptedOn
          jobOrderAcceptedBy
          jobOrderFilePath
          paymentMethod
          paymentFrequency
          paymentDay
          hasChild
          parentWorkOrderId
          services {
            workOrderServiceId
            mdServiceId
            serviceMeasure
            mdServiceUnits
            taskSchedule {
              taskScheduleId
              mdTaskId
              isProofOfCompletionRequired
            }
          }
          shiftDetails {
            shiftDetailId
            startTime
            endTime
            punchInGraceMinutes
            punchOutGraceMinutes
          }
          jobBidSummary {
            jobBidSummaryId
            jobDetails
            bidDetails
          }
          bidApprovalStatus
          supervisorIds
        }
        hasChild
        parentWorkOrderId
        jobCreator
        bidApprovalStatus
        allowVendorToReschedule
        isPaid
        workOrderRating
        isInspectionRequiredForParentJob
        supervisorIds
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const getAllPreviouslyWorkedWithVendors = /* GraphQL */ `
  query GetAllPreviouslyWorkedWithVendors($customerId: String!) {
    getAllPreviouslyWorkedWithVendors(customerId: $customerId) {
      vendorId
      vendorEmail
    }
  }
`;
export const getWorkDay = /* GraphQL */ `
  query GetWorkDay($workDayId: String!) {
    getWorkDay(workDayId: $workDayId) {
      isReworkDay
      isShiftRescheduled
      facilityId
      workDayId
      workWeeks
      proofOfCompletion
      timeSheets
      workDate
      geolocation
      geofencing
      mandatoryTimeOfPresence
      calculatedTimeToComplete
      actualTimeToComplete
      routeTracking
      taskRemindersAndAlerts
      issuePrepaidCard
      communication
      reworkTasks
      ratings
      mdRatingTypes
      preShiftChecklistId
      workOrderId
      startTime
      endTime
    }
  }
`;
export const findWorkDay = /* GraphQL */ `
  query FindWorkDay(
    $workOrderId: String!
    $actualWorkDate: String
    $workDate: String
  ) {
    findWorkDay(
      workOrderId: $workOrderId
      actualWorkDate: $actualWorkDate
      workDate: $workDate
    ) {
      isReworkDay
      isShiftRescheduled
      facilityId
      workDayId
      workWeeks
      proofOfCompletion
      timeSheets
      workDate
      geolocation
      geofencing
      mandatoryTimeOfPresence
      calculatedTimeToComplete
      actualTimeToComplete
      routeTracking
      taskRemindersAndAlerts
      issuePrepaidCard
      communication
      reworkTasks
      ratings
      mdRatingTypes
      preShiftChecklistId
      workOrderId
      startTime
      endTime
    }
  }
`;
export const schedulesByDate = /* GraphQL */ `
  query SchedulesByDate(
    $workOrderId: String
    $scheduleDate: String
    $workOrderScheduleId: String
  ) {
    schedulesByDate(
      workOrderId: $workOrderId
      scheduleDate: $scheduleDate
      workOrderScheduleId: $workOrderScheduleId
    ) {
      workOrderScheduleId
      workOrderId
      woScheduleMasterId
      scheduleDate
      scheduleStartTime
      scheduleEndTime
      scheduleType
      dataStatus
      scheduleName
      scheduleSubName
      startTime
      endTime
      tasksAvailable
      isRescheduled
      rescheduledDate
      facilityId
      jobStartDate
      jobEndDate
      actualStartDate
      actualEndDate
      endDateOffset
    }
  }
`;
export const scheduleMasterServices = /* GraphQL */ `
  query ScheduleMasterServices($woScheduleMasterId: String!) {
    scheduleMasterServices(woScheduleMasterId: $woScheduleMasterId) {
      mdServiceId
      mdServiceName
      tasks {
        mdTaskId
        mdTaskName
        woScheduleServiceId
        isProofOfCompletionRequired
      }
    }
  }
`;
export const allScheduleDetails = /* GraphQL */ `
  query AllScheduleDetails($workOrderId: String) {
    allScheduleDetails(workOrderId: $workOrderId) {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleStartDate
      scheduleName
      scheduleEndDate
      endDateOffset
      workOrder {
        jobStartDate
        jobEndDate
        actualStartDate
        actualEndDate
      }
      scheduleAssignments {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        woScheduleMasterId
        taskAssignments {
          taskId
          taskAssignmentId
          assignedBy
          assignedOn
          associateId
        }
        associateStartDate
        associateEndDate
        cognitoUserId
        workDayId
        noShow
        cantMakeIt
        jobAccepted
        workDayTeam {
          workOrderId
          workDayId
          facilityId
          associateId
        }
        username
        roleName
      }
      scheduleServices {
        mdServiceId
        mdServiceName
        tasks {
          mdTaskId
          mdTaskName
          woScheduleServiceId
          isProofOfCompletionRequired
        }
      }
      preShiftCheckListTemplates {
        preShiftChecklistTemplateId
        woScheduleMasterId
        checkListName
        checkListIconUrl
        mdPreShiftCheckListCategoryId
        buildingAccessConfirmation
        securityAccessConfirmation
        safetyConfirmation
        msdsChemicalSafety
        workStatus
        workOrderId
        workOrderRecSeq
        preShiftChecklistTemplateItems {
          itemName
          preShiftChecklistTemplateItemId
          mdItemActionType
          quantity
          mdQuantityType
        }
      }
    }
  }
`;
export const upcomingShifts = /* GraphQL */ `
  query UpcomingShifts(
    $fromDate: String
    $toDate: String
    $workDayTeamStatus: String
    $limit: Int
    $pageNumber: Int
    $searchText: String
    $vendorId: String
    $workOrderId: String
  ) {
    upcomingShifts(
      fromDate: $fromDate
      toDate: $toDate
      workDayTeamStatus: $workDayTeamStatus
      limit: $limit
      pageNumber: $pageNumber
      searchText: $searchText
      vendorId: $vendorId
      workOrderId: $workOrderId
    ) {
      data {
        workDayId
        workOrderName
        vendorId
        vendorName
        facilityId
        facilityName
        facilityAddress
        customerId
        jobStartTime
        jobEndTime
        jobStartDate
        parentWorkOrderId
        hasChild
        jobEndDate
        workOrderId
        workDate
        plannedStartTime
        plannedEndTime
        jobId
        latestPunchRecord {
          punchTime
          punchType
        }
        jobType
        hasReworkTasks
        jobCustodian
        bidId
        services {
          workOrderServiceId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          taskSchedule {
            taskScheduleId
            mdTaskId
            isProofOfCompletionRequired
          }
        }
        isReworkDay
        isShiftRescheduled
        shiftStartDateTime
        shiftEndDateTime
        woScheduleMasterId
        shiftDetails {
          shiftDetailId
          startTime
          endTime
          punchInGraceMinutes
          punchOutGraceMinutes
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const associateProfile = /* GraphQL */ `
  query AssociateProfile($id: String) {
    associateProfile(id: $id) {
      associateProfileRecId
      associateId
      cognitoUserId
      title
      linkedInURL
      dateOfBirth
      name
      emergencyContactName
      emergencyContactNumber
      eeoInfo
      primaryLanguage
      languagesSpoken
      source
      logoUrl
      emailId
    }
  }
`;
export const associateAddress = /* GraphQL */ `
  query AssociateAddress($id: String) {
    associateAddress(id: $id) {
      addressId
      associateId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      regionId
    }
  }
`;
export const getAssociateDetailsByUserId = /* GraphQL */ `
  query GetAssociateDetailsByUserId($cognitoUserId: String) {
    getAssociateDetailsByUserId(cognitoUserId: $cognitoUserId) {
      associateProfileRecId
      associateId
      cognitoUserId
      title
      linkedInURL
      dateOfBirth
      name
      emergencyContactName
      emergencyContactNumber
      eeoInfo
      primaryLanguage
      languagesSpoken
      source
      logoUrl
      emailId
    }
  }
`;
export const associateServiceLocation = /* GraphQL */ `
  query AssociateServiceLocation($id: String!) {
    associateServiceLocation(id: $id) {
      associateServiceLocationId
      associateId
      mdAreaName
      mdAreaType
      mdAreaCategory
      locationId
      country
    }
  }
`;
export const associateServiceLocations = /* GraphQL */ `
  query AssociateServiceLocations($associateId: String!) {
    associateServiceLocations(associateId: $associateId) {
      associateServiceLocationId
      associateId
      mdAreaName
      mdAreaType
      mdAreaCategory
      locationId
      country
    }
  }
`;
export const associateShiftPreferences = /* GraphQL */ `
  query AssociateShiftPreferences($id: String!, $limit: Int, $pageNum: Int) {
    associateShiftPreferences(id: $id, limit: $limit, pageNum: $pageNum) {
      data {
        shiftPreferenceId
        day
        fromTime
        toTime
        isFlexibleShiftPreference
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const associateShiftPreferencesByDay = /* GraphQL */ `
  query AssociateShiftPreferencesByDay(
    $id: String!
    $day: String!
    $limit: Int
    $pageNum: Int
  ) {
    associateShiftPreferencesByDay(
      id: $id
      day: $day
      limit: $limit
      pageNum: $pageNum
    ) {
      data {
        shiftPreferenceId
        day
        fromTime
        toTime
        isFlexibleShiftPreference
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const associateSkills = /* GraphQL */ `
  query AssociateSkills($associateId: String!, $limit: Int, $pageNum: Int) {
    associateSkills(
      associateId: $associateId
      limit: $limit
      pageNum: $pageNum
    ) {
      data {
        skillId
        skillCategoryId
        skillSubCategoryId
        skillDescription
        associateSkillsId
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const associateSkillsSummary = /* GraphQL */ `
  query AssociateSkillsSummary(
    $associateId: String!
    $limit: Int
    $pageNum: Int
  ) {
    associateSkillsSummary(
      associateId: $associateId
      limit: $limit
      pageNum: $pageNum
    ) {
      data {
        skillCategoryId
        imageUrl
        skillCategoryName
        subCategoryCount
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const customerAddresses = /* GraphQL */ `
  query CustomerAddresses($customerId: String!) {
    customerAddresses(customerId: $customerId) {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
    }
  }
`;
export const customerAddress = /* GraphQL */ `
  query CustomerAddress($id: String!) {
    customerAddress(id: $id) {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
    }
  }
`;
export const customers = /* GraphQL */ `
  query Customers($limit: Int, $pageNumber: Int) {
    customers(limit: $limit, pageNumber: $pageNumber) {
      data {
        customerId
        legalName
        doingBusinessAs
        websiteUrl
        federalEmployerId
        SICCode
        taxId
        logo
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const customer = /* GraphQL */ `
  query Customer($id: String!) {
    customer(id: $id) {
      customerId
      legalName
      doingBusinessAs
      websiteUrl
      federalEmployerId
      SICCode
      taxId
      logo
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($filterCustomerInput: FilterCustomerInput) {
    getCustomers(filterCustomerInput: $filterCustomerInput) {
      customerId
      legalName
      email
      intId
      address {
        addressId
        customerId
        addressName
        noOfBuildings
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        logoUrl
        intId
      }
    }
  }
`;
export const customerDetails = /* GraphQL */ `
  query CustomerDetails($id: String!) {
    customerDetails(id: $id) {
      customerDetailsId
      customerId
      noOfEmployees
      annualRevenueEstimate
      mdCustomerType
      referralSource
      phone
      fax
      email
    }
  }
`;
export const facilities = /* GraphQL */ `
  query Facilities(
    $customerId: String!
    $limit: Int
    $pageNumber: Int
    $facilityName: String
    $intId: String
    $sortBy: String
    $sortOrder: String
  ) {
    facilities(
      customerId: $customerId
      limit: $limit
      pageNumber: $pageNumber
      facilityName: $facilityName
      intId: $intId
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      data {
        callHours
        transportationName
        storageName
        callStartTime
        callEndTime
        facilityId
        customerId
        buildingName
        buildingMakeup
        noOfFloors
        sqFootage
        mdFacilityType
        serviceableSqFootage
        occupancy
        supplyStorageAvailability
        transportRequiredWithinLocation
        contactId
        facilityTrafficLevel
        emergencyContactName
        emergencyContactPhone
        cleaningInstructions
        geoFenceRadius
        addressId
        address {
          addressId
          customerId
          addressName
          noOfBuildings
          addressLine1
          addressLine2
          addressLine3
          city
          stateCode
          county
          postalCode
          mdCountryCode
          landmark
          googlePlaceId
          latitude
          longitude
          mdMsaId
          logoUrl
          intId
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const facility = /* GraphQL */ `
  query Facility($id: String!, $customerId: String!) {
    facility(id: $id, customerId: $customerId) {
      callHours
      transportationName
      storageName
      callStartTime
      callEndTime
      facilityId
      customerId
      buildingName
      buildingMakeup
      noOfFloors
      sqFootage
      mdFacilityType
      serviceableSqFootage
      occupancy
      supplyStorageAvailability
      transportRequiredWithinLocation
      contactId
      facilityTrafficLevel
      emergencyContactName
      emergencyContactPhone
      cleaningInstructions
      geoFenceRadius
      addressId
      address {
        addressId
        customerId
        addressName
        noOfBuildings
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        logoUrl
        intId
      }
    }
  }
`;
export const facilityWithLocation = /* GraphQL */ `
  query FacilityWithLocation($inputFacilityIds: [String]!) {
    facilityWithLocation(inputFacilityIds: $inputFacilityIds) {
      callHours
      transportationName
      storageName
      callStartTime
      callEndTime
      facilityId
      customerId
      buildingName
      buildingMakeup
      noOfFloors
      sqFootage
      mdFacilityType
      serviceableSqFootage
      occupancy
      supplyStorageAvailability
      transportRequiredWithinLocation
      contactId
      facilityTrafficLevel
      emergencyContactName
      emergencyContactPhone
      cleaningInstructions
      geoFenceRadius
      addressId
      address {
        addressId
        customerId
        addressName
        noOfBuildings
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        logoUrl
        intId
      }
    }
  }
`;
export const customerFacilityAccess = /* GraphQL */ `
  query CustomerFacilityAccess($customerId: String!, $facilityId: String!) {
    customerFacilityAccess(customerId: $customerId, facilityId: $facilityId) {
      facilityAccessDetailId
      facilityId
      jobId
      accessContactPerson
      roleName
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      from
      to
    }
  }
`;
export const facilityServiceDetails = /* GraphQL */ `
  query FacilityServiceDetails($facilityDetailsId: String!) {
    facilityServiceDetails(facilityDetailsId: $facilityDetailsId) {
      facilityDetailsId
      facilityId
      floorNumber
      mdAreaType
      mdFlooringType
      quantity
      mdUnitType
    }
  }
`;
export const allFacilitiesServiceDetails = /* GraphQL */ `
  query AllFacilitiesServiceDetails($facilityId: String!) {
    allFacilitiesServiceDetails(facilityId: $facilityId) {
      facilityDetailsId
      facilityId
      floorNumber
      mdAreaType
      mdFlooringType
      quantity
      mdUnitType
    }
  }
`;
export const facilityServiceSpecification = /* GraphQL */ `
  query FacilityServiceSpecification(
    $customerId: String!
    $facilityId: String!
  ) {
    facilityServiceSpecification(
      customerId: $customerId
      facilityId: $facilityId
    ) {
      facilityId
      floorNumber
      mdServiceId
      serviceQuantity
      mdServiceUnitType
    }
  }
`;
export const getAllCustomersForVendor = /* GraphQL */ `
  query GetAllCustomersForVendor($vendorId: String) {
    getAllCustomersForVendor(vendorId: $vendorId) {
      legalName
      customerId
    }
  }
`;
export const beneficiaries = /* GraphQL */ `
  query Beneficiaries($vendorId: String!, $dataStatus: DataState) {
    beneficiaries(vendorId: $vendorId, dataStatus: $dataStatus) {
      dataStatus
      beneficiaryId
      name
      TIN
      percentageHolding
      addressLine1
      addressLine2
      city
      state
      zipCode
      vendorId
      modifiedOn
    }
  }
`;
export const beneficiary = /* GraphQL */ `
  query Beneficiary($beneficiaryId: String!) {
    beneficiary(beneficiaryId: $beneficiaryId) {
      dataStatus
      beneficiaryId
      name
      TIN
      percentageHolding
      addressLine1
      addressLine2
      city
      state
      zipCode
      vendorId
      modifiedOn
    }
  }
`;
export const insurances = /* GraphQL */ `
  query Insurances($vendorId: String!, $dataStatus: DataState) {
    insurances(vendorId: $vendorId, dataStatus: $dataStatus) {
      fileName
      insuranceName
      validTill
      producer
      insured
      contactName
      contactPhone
      address
      fax
      insuranceId
      dataStatus
      vendorId
      createdOn
    }
  }
`;
export const insurance = /* GraphQL */ `
  query Insurance($insuranceId: String!) {
    insurance(insuranceId: $insuranceId) {
      fileName
      insuranceName
      validTill
      producer
      insured
      contactName
      contactPhone
      address
      fax
      insuranceId
      dataStatus
      vendorId
      createdOn
    }
  }
`;
export const allInsuranceAttachment = /* GraphQL */ `
  query AllInsuranceAttachment($insuranceId: String!, $dataStatus: DataStatus) {
    allInsuranceAttachment(insuranceId: $insuranceId, dataStatus: $dataStatus) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const findManyVendorAttachments = /* GraphQL */ `
  query FindManyVendorAttachments($vendorId: String!, $dataStatus: String!) {
    findManyVendorAttachments(vendorId: $vendorId, dataStatus: $dataStatus) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const vendorAttachments = /* GraphQL */ `
  query VendorAttachments($vendorId: String!) {
    vendorAttachments(vendorId: $vendorId) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const allVendorsOnARK = /* GraphQL */ `
  query AllVendorsOnARK(
    $name: String
    $dataStatus: String
    $email: String
    $otherVendorSearchInput: OtherVendorSearchInput
  ) {
    allVendorsOnARK(
      name: $name
      dataStatus: $dataStatus
      email: $email
      otherVendorSearchInput: $otherVendorSearchInput
    ) {
      vendorId
      vendorCompanyName
      vendorCompanyType
      doingBusinessAs
      SSID
      taxId
      websiteUrl
      logoUrl
      referralSource
      phone
      email
      dataStatus
      modifiedOn
    }
  }
`;
export const vendorAddress = /* GraphQL */ `
  query VendorAddress($id: String!) {
    vendorAddress(id: $id) {
      addressId
      vendorId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      modifiedOn
      regionId
    }
  }
`;
export const vendorAddresses = /* GraphQL */ `
  query VendorAddresses($vendorId: String!) {
    vendorAddresses(vendorId: $vendorId) {
      addressId
      vendorId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      modifiedOn
      regionId
    }
  }
`;
export const vendorDetails = /* GraphQL */ `
  query VendorDetails($vendorId: String!) {
    vendorDetails(vendorId: $vendorId) {
      vendorId
      annualRevenueEstimate
      fax
      coi
      noOfEmployees
      workWeekDefinition
      w9FormUrl
    }
  }
`;
export const vendorRoles = /* GraphQL */ `
  query VendorRoles($vendorId: String!, $userId: String) {
    vendorRoles(vendorId: $vendorId, userId: $userId) {
      roleId
      vendorId
      roleName
      team {
        userId
        name
      }
    }
  }
`;
export const vendorServiceLocations = /* GraphQL */ `
  query VendorServiceLocations(
    $vendorId: String!
    $limit: Int
    $pageNumber: Int
  ) {
    vendorServiceLocations(
      vendorId: $vendorId
      limit: $limit
      pageNumber: $pageNumber
    ) {
      data {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const vendorServiceLocation = /* GraphQL */ `
  query VendorServiceLocation($id: String!) {
    vendorServiceLocation(id: $id) {
      vendorServiceLocationId
      vendorId
      mdAreaName
      mdAreaType
      mdAreaCode
      mdAreaCategory
      country
      locationId
    }
  }
`;
export const vendorServicesOffered = /* GraphQL */ `
  query VendorServicesOffered(
    $vendorId: String!
    $pageNumber: Int
    $limit: Int
  ) {
    vendorServicesOffered(
      vendorId: $vendorId
      pageNumber: $pageNumber
      limit: $limit
    ) {
      data {
        vendorServiceOfferedId
        serviceCategoryId
        serviceDescription
        serviceSubCategoryId
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const vendorServicesOfferedSummary = /* GraphQL */ `
  query VendorServicesOfferedSummary($vendorId: String!) {
    vendorServicesOfferedSummary(vendorId: $vendorId) {
      imageUrl
      serviceCategoryName
      subCategoryCount
      mdServiceType
    }
  }
`;
export const vendorTeam = /* GraphQL */ `
  query VendorTeam($vendorId: String!) {
    vendorTeam(vendorId: $vendorId) {
      vendorId
      roleId
      email
      name
      userId
      roleName
      phoneNumber
    }
  }
`;
export const vendor = /* GraphQL */ `
  query Vendor($id: String!) {
    vendor(id: $id) {
      vendorId
      vendorCompanyName
      vendorCompanyType
      doingBusinessAs
      SSID
      taxId
      websiteUrl
      logoUrl
      referralSource
      phone
      email
      dataStatus
      modifiedOn
      vendorRating
      vendorAddress {
        addressId
        vendorId
        addressName
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        modifiedOn
        regionId
      }
    }
  }
`;
export const vendors = /* GraphQL */ `
  query Vendors($limit: Int, $pageNumber: Int) {
    vendors(limit: $limit, pageNumber: $pageNumber) {
      data {
        vendorId
        vendorCompanyName
        vendorCompanyType
        doingBusinessAs
        SSID
        taxId
        websiteUrl
        logoUrl
        referralSource
        phone
        email
        dataStatus
        modifiedOn
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const findManyVendorAccreditation = /* GraphQL */ `
  query FindManyVendorAccreditation($vendorId: String!) {
    findManyVendorAccreditation(vendorId: $vendorId) {
      recordId
      attachmentId
      certificateId
      accreditationType
      labels
      issuingAuthority
      effectiveFromDate
      effectiveEndDate
      accreditationSubType {
        accreditationSubTypeId
        recSeq
        recStatus
        subType
        value
        dataStatus
        vendorAccreditationId
        vendorAccreditationRecSeq
        fromDate
        endDate
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      msaCustomer
      type
      status
      dataStatus
      createdBy
      modifiedBy
      createdOn
      modifiedOn
      fromDate
      vendorId
      attachments {
        attachmentId
        refType
        refId
        fileName
        mdFileContext
        fileExtension
        url
        dataStatus
        date
        createdOn
        modifiedOn
      }
    }
  }
`;
